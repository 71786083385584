/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import {configureFonts, getFontThemeList} from '@nfq/next-fonts';
import {createGlobalStyle, keyframes} from 'styled-components';

import type {Config} from '@nfq/react-grid';
import type {DefaultTheme} from 'styled-components';

export const fontDefinitions = configureFonts({
    Dinot: [
        {
            fontDisplay: 'swap',
            fontStyle: 'normal',
            fontWeight: '300',
            preload: true,
            src: '/fonts/dinot-300.woff2'
        },
        {
            fontDisplay: 'swap',
            fontStyle: 'normal',
            fontWeight: 'normal',
            preload: true,
            src: '/fonts/dinot-400.woff2'
        },
        {
            fontDisplay: 'swap',
            fontStyle: 'normal',
            fontWeight: '500',
            preload: true,
            src: '/fonts/dinot-500.woff2'
        },
        {
            fontDisplay: 'swap',
            fontStyle: 'normal',
            fontWeight: 'bold',
            preload: true,
            src: '/fonts/dinot-700.woff2'
        }
    ],
    Lato: [
        {
            fontDisplay: 'swap',
            fontStyle: 'normal',
            fontWeight: 'bold',
            preload: true,
            src: '/fonts/lato-bold.woff2'
        },
        {
            fontDisplay: 'swap',
            fontStyle: 'normal',
            fontWeight: 'normal',
            preload: true,
            src: '/fonts/lato-regular.woff2'
        }
    ]
});

const nfqgrid: Config = {
    baseSpacing: 0.4,
    container: {
        lg: 960,
        md: 740,
        sm: 540,
        xl: 1140,
        xs: 'fluid',
        xxl: 1140
    }
};

export enum Colors {
    /** Background color for the advent calendar. ![#A70317](https://via.placeholder.com/12/A70317/A70317.png) `#A70317`. */
    adventCalendarPageBackground = '#A70317',
    /** Dark font color for the advent calendar. ![rgba(0, 0, 0, 0.8)](https://via.placeholder.com/12/000000CC/000000CC.png) `rgba(0, 0, 0, 0.8)`. */
    adventCalendarFontColorDark = 'rgba(0, 0, 0, 0.8)',
    /** Background color for ultra header. ![#262626](https://via.placeholder.com/12/262626/262626.png) `#262626`. */
    ultraAccessoriseBackgroundColor = '#262626',
    /** Background color for ultra header. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    ultraHeaderBackgroundColor = '#000000',
    /** Background color for button used in ultra header. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    ultraHeaderButtonBgColor = '#000000',
    /** Background color for dropdown component. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    ultraDropdownBgColor = '#000000',
    /** Background color for navigation component. ![#232324](https://via.placeholder.com/12/232324/232324.png) `#232324`. */
    ultraNavbarNavBackgroundColor = '#232324',
    /** Base background color for the ultra campaign. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    ultraPageBackground = '#000000',
    /** Base foreground color for the ultra campaign. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    ultraPageForeground = '#FFFFFF',
    /** The secondary color for the ultra campaign. ![#B7A9A4](https://via.placeholder.com/12/B7A9A4/B7A9A4.png) `#B7A9A4`. */
    ultraSecondaryColor = '#B7A9A4',
    /** The secondary hover color for the ultra campaign. ![#9E928E](https://via.placeholder.com/12/9E928E/9E928E.png) `#9E928E`. */
    ultraSecondaryHoverColor = '#9E928E',
    /** Focus color for the interactive elements in the ultra campaign. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    ultraFocusColor = '#FFFFFF',
    /** Danger color for the ultra campaign. ![#A70317](https://via.placeholder.com/12/A70317/A70317.png) `#A70317`. */
    ultraDangerColor = '#A70317',
    /** Modal BG ultra campaign. ![#1C1C1C](https://via.placeholder.com/12/1C1C1C/1C1C1C.png) `#1C1C1C`. */
    ultraModalBg = '#1C1C1C',
    /** Thank you background color. ![#1C1C1C](https://via.placeholder.com/12/1C1C1C/1C1C1C.png) `#1C1C1C`. */
    ultraThankyouBgMobile = '#1C1C1C',
    /** Thank you light background color. ![#2C2C2D](https://via.placeholder.com/12/2C2C2D/2C2C2D.png) `#2C2C2D`. */
    ultraThankyouBgLight = '#2C2C2D',
    /** Thank you background color. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    ultraThankyouBgDark = '#000000',
    /** Button disabled color. ![#8A2733](https://via.placeholder.com/12/8A2733/8A2733.png) `#8A2733`. */
    buttonDisabledColor = '#8A2733',
    /** Page background color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    pageBackground = '#FFFFFF',
    /** Primary font color. ![#313636](https://via.placeholder.com/12/313636/313636.png) `#313636`. */
    primaryFontColor = '#313636',
    /** Primary font accent color. ![#FEEDED](https://via.placeholder.com/12/FEEDED/FEEDED.png) `#FEEDED`. */
    primaryFontAccentColor = '#FEEDED',
    /** Primary action color. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    primaryActionColor = '#000000',
    /** Primary action font color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    primaryActionFontColor = '#FFFFFF',
    /** Secondary font color. ![#192630](https://via.placeholder.com/12/192630/192630.png) `#192630`. */
    secondaryFontColor = '#192630',
    /** Secondary action color. ![#EAEAEA](https://via.placeholder.com/12/EAEAEA/EAEAEA.png) `#EAEAEA`. */
    secondaryActionColor = '#EAEAEA',
    /** Secondary action font color. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    secondaryActionFontColor = '#000000',
    /** Table Active Head font color if sorting is active. ![#99938D](https://via.placeholder.com/12/99938D/99938D.png) `#99938D`. */
    tableActiveHeadColor = '#99938D',
    /** Navbar font color. ![#E0DFDF](https://via.placeholder.com/12/E5EAEE/E5EAEE.png) `#E5EAEE`. */
    navbarFontColor = '#E0DFDF',
    /** Table border color. ![#E5EAEE](https://via.placeholder.com/12/E5EAEE/E5EAEE.png) `#E5EAEE`. */
    tableBorderColor = '#E5EAEE',
    /** Table dark background color. ![#F3F5F6](https://via.placeholder.com/12/F3F5F6/F3F5F6.png) `#F3F5F6`. */
    tableDarkBodyColor = '#F3F5F6',
    /** Border Hover color. ![#E8E8E9D1](https://via.placeholder.com/12/E8E8E9D1/E8E8E9D1.png) `#E8E8E9D1`. */
    hoverColor = '#E8E8E9D1',
    /** Table Hover background color. ![#E0F2FF](https://via.placeholder.com/12/E0F2FF/E0F2FF.png) `#E0F2FF`. */
    tableHoverBodyColor = '#E0F2FF',
    /** Danger color. ![#CB4D37](https://via.placeholder.com/12/CB4D37/CB4D37.png) `#CB4D37`. */
    dangerColor = '#CB4D37',
    /** Success color. ![#207732](https://via.placeholder.com/12/207732/207732.png) `#207732`. */
    successColor = '#207732',
    /** Primary color. ![#3B3A3A](https://via.placeholder.com/12/3B3A3A/3B3A3A.png) `#3B3A3A`. */
    primaryColor = '#3B3A3A',
    /** Primary Raredeals font color. ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    raredealsPrimaryFontColor = '#000000',
    /** Light Raredeals font color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    raredealsLightFontColor = '#FFFFFF',
    /** Raredeals button, primary bg color . ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    raredealsButtonPrimaryBgColor = '#000000',
    /** Raredeals button, secondary bg color . ![#003888](https://via.placeholder.com/12/003888/003888.png) `#003888`. */
    raredealsButtonSecondaryBgColor = '#003888',
    /** Raredeals button, Tertiary bg color . ![#F3F3F3](https://via.placeholder.com/12/F3F3F3/F3F3F3.png) `#F3F3F3`. */
    raredealsButtonTertiaryBgColor = '#FFFFFF',
    /** Raredeals button, Tertiary bg color . ![#F3F3F3](https://via.placeholder.com/12/F3F3F3/F3F3F3.png) `#F3F3F3`. */
    raredealsButtonTertiaryBgHoverColor = '#F3F3F3',
    /** Raredeals tertiary button border color . ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    raredealsButtonTertiaryBorderColor = '#000000',
    /** Raredeals cart bubble background color . ![#DD001B](https://via.placeholder.com/12/DD001B/DD001B.png) `#DD001B`. */
    raredealsCartBubbleBgColor = '#DD001B',
    /** Raredeals footer background color . ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    raredealsFooterBgColor = '#000000',
    /** Raredeals howITWorks background color . ![#000000](https://via.placeholder.com/12/000000/000000.png) `#000000`. */
    raredealsHowItWorksBgColor = '#000000'
}

export enum BoxShadows {
    /** The default dropshadow for Popovers. */
    popOverShadow = '0px 4px 4px 0px rgba(67, 67, 67, 0.15)',
    // eslint-disable-next-line max-len
    countdownComponentShadow = '0 1px 2px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.09), 0 8px 5px rgba(0, 0, 0, 0.05), 0 14px 5px rgba(0, 0, 0, 0.01)'
}

export const theme: DefaultTheme = {
    boxShadows: BoxShadows,
    colors: Colors,
    fonts: getFontThemeList(fontDefinitions),
    nfqgrid
};

const skeleton = keyframes`
    100% {
        transform: translateX(100%);
    }
`;

export const GlobalStyle = createGlobalStyle`
    *,
    &::before,
    &::after {
        box-sizing: border-box;
    }

    * {
        -webkit-tap-highlight-color: transparent;
        -webkit-tap-highlight-color: transparent;
    }

    html {
        font-size: 10px;
    }

    html, body {
        background-color: ${({theme: globalTheme}) => globalTheme.colors.pageBackground};
        color: ${({theme: globalTheme}) => globalTheme.colors.primaryFontColor};
        margin: 0;
        min-height: 100%;
        padding: 0;
        scroll-behavior: smooth;
    }

    body.scroll-lock {
        overflow: hidden;
    }

    #__next {
        background-color: ${({theme: globalTheme}) => globalTheme.colors.pageBackground};
        min-height: 100%;
    }

    .react-loading-skeleton {
        --base-color: rgba(125, 125, 125, 0.3);
        --base-color-highlight: rgba(125, 125, 125, 0);
        --highlight-color: rgba(125, 125, 125, 0.3);
        --animation-duration: 1.5s;
        --animation-direction: normal;
        --pseudo-element-display: block; /* Enable animation */

        background-color: var(--base-color);
        border-radius: 0.4rem;
        display: inline-flex;
        line-height: 1;
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
    }

    .react-loading-skeleton::after {
        animation-direction: var(--animation-direction);
        animation-duration: var(--animation-duration);
        animation-iteration-count: infinite;
        animation-name: ${skeleton};
        animation-timing-function: ease-in-out;
        background-image: linear-gradient(90deg, var(--base-color-highlight), var(--highlight-color), var(--base-color-highlight));
        background-repeat: no-repeat;
        content: ' ';
        display: var(--pseudo-element-display);
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        transform: translateX(-100%);
    }
`;